<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <router-link :to="{ name: 'CompanyNewUser' }">
          <base-button class="ml-2" size="sm" simple type="success">
            {{ $t("newUser") }}
          </base-button>
        </router-link>


        <base-button class="ml-2" size="sm" simple type="warning" v-if="massEditing.length" :disabled="requestOnUp" @click="runGenerateReports()">
          {{ $t("generateReports") }} ({{ massEditing.length }})
        </base-button>

      </card>
    </div>

    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">
            {{ $t("users") }} ({{ users.length }})
            <span v-if="search"> <badge type="warning"> {{ $t("filter") }}: {{ search }} </badge> <base-button size="sm" type="danger" @click="() => search='' "> X </base-button> </span>
          </h4>
        </template>

        <div v-if="users.length && requestOn === false">
          <el-table
            class="table-striped"
            height="61vh"
            ref="multipleOrders"
            :default-sort="{ prop: 'name', order: 'ascending' }"
            :data="users.filter(user => !search || user.name.toLowerCase().includes(search.toLowerCase()))">

            <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="row">
                    <div class="col-md-7">
                      <strong class="tim-icons icon-pencil c-p" primary @click="action(scope.row, 'CompanyEditUser')"> {{ $t("orders.massEditing") }} </strong>
                      <strong class="tim-icons icon-chart-bar-32 ml-5 c-p text-success" primary @click="action(scope.row, 'CompanyOverviewUser')"> {{ $t("sidebar.overview") }} </strong>
                      <strong class="tim-icons icon-alert-circle-exc ml-5 c-p text-danger" primary @click="action(scope.row, 'CompanyErrorsUser')"> {{ $t("sidebar.errors") }} </strong>
                      <strong class="tim-icons icon-notes ml-5 c-p" @click="action(scope.row, 'CompanyOrdersUser')"> {{ $t("orders.title") }}  </strong>
                      <strong class="tim-icons icon-cart ml-5 c-p text-warning" @click="action(scope.row, 'CompanyOperationsOpenUser')"> {{ $t("route.OperationsOpen") }}  </strong>
                      <strong class="tim-icons icon-check-2 ml-5 c-p text-success" @click="action(scope.row, 'CompanyOperationsClosedUser')"> {{ $t("route.OperationsClosed") }}  </strong>
                    </div>
                    <div class="col-md-5">
                      <collapse :activeIndex="0">
                        <collapse-item :title="$t('suspendOrActivateAccounts')">
                          <base-button simple size="sm" :type="scope.row.suspendedBinance ? 'danger' : 'success'" :disabled="requestOnUp"
                            @click="actionUser(scope.row.id, 'suspendedBinance', !scope.row.suspendedBinance)" class="ml-3"> Binance </base-button>

                          <base-button simple size="sm" :type="scope.row.suspendedKucoin ? 'danger' : 'success'" :disabled="requestOnUp"
                            @click="actionUser(scope.row.id, 'suspendedKucoin', !scope.row.suspendedKucoin)" class="ml-3"> Kucoin </base-button>

                          <base-button simple size="sm" :type="scope.row.suspendedHuobi ? 'danger' : 'success'" :disabled="requestOnUp"
                            @click="actionUser(scope.row.id, 'suspendedHuobi', !scope.row.suspendedHuobi)" class="ml-3"> Huobi </base-button>

                          <base-button simple size="sm" :type="scope.row.suspendedOkex ? 'danger' : 'success'" :disabled="requestOnUp"
                            @click="actionUser(scope.row.id, 'suspendedOkex', !scope.row.suspendedOkex)" class="ml-3"> Okex </base-button>

                          <base-button simple size="sm" type="warning" class="ml-3" @click="copyText(scope.row.auth)"> copy URI </base-button>
                        </collapse-item>
                      </collapse>
                    </div>
                  </div>
                </template>
            </el-table-column>

            <el-table-column
              v-if="loadingTime"
              prop="selected"
              :label="$t('orders.selected')"
              >
              <template slot="header">
                <base-checkbox class="mb-3" v-model="switchOn" @input="verifySelected"></base-checkbox>
              </template>

              <template slot-scope="scope">
                <base-checkbox class="mb-3" :disabled="requestOnUp" v-model="scope.row.selected"></base-checkbox>
              </template>
            </el-table-column>

            <el-table-column color="primary" min-width="160" prop="name" :label="$t('companyUser.name')">
              <template slot="header">
                <base-input v-model="search" type="text" :placeholder="$t('companyUser.name')" />
              </template>
            </el-table-column>

            <el-table-column :label="$t('binance')">
              <el-table-column prop="binanceActive" min-width="160" :label="$t('activeSuspended')">
                <template slot-scope="scope">
                  <span :class="{ 'text-success': scope.row.binanceActive, 'text-danger': !scope.row.binanceActive }"> {{ scope.row.binanceActive ? $t('yes') : $t('no') }} </span>
                  /
                  <span :class="{ 'text-success': !scope.row.suspendedBinance, 'text-danger': scope.row.suspendedBinance }"> {{ scope.row.suspendedBinance ? $t('yes') : $t('no') }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="usdtBinance"  label="USDT" />
              <el-table-column prop="busdBinance" label="BUSD" />
            </el-table-column>

            <el-table-column :label="$t('kucoin')">
              <el-table-column prop="kucoinActive" min-width="160" :label="$t('activeSuspended')">
                <template slot-scope="scope">
                  <span :class="{ 'text-success': scope.row.kucoinActive, 'text-danger': !scope.row.kucoinActive }"> {{ scope.row.kucoinActive ? $t('yes') : $t('no') }} </span>
                  /
                  <span :class="{ 'text-success': !scope.row.suspendedKucoin, 'text-danger': scope.row.suspendedKucoin }"> {{ scope.row.suspendedKucoin ? $t('yes') : $t('no') }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="usdtKucoin"  label="USDT" />
            </el-table-column>

            <el-table-column :label="$t('huobi')">
              <el-table-column prop="huobiActive" min-width="160" :label="$t('activeSuspended')">
                <template slot-scope="scope">
                  <span :class="{ 'text-success': scope.row.huobiActive, 'text-danger': !scope.row.huobiActive }"> {{ scope.row.huobiActive ? $t('yes') : $t('no') }} </span>
                  /
                  <span :class="{ 'text-success': !scope.row.suspendedHuobi, 'text-danger': scope.row.suspendedHuobi }"> {{ scope.row.suspendedHuobi ? $t('yes') : $t('no') }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="usdtHuobi"  label="USDT" />
              <el-table-column prop="husdHuobi"  label="HUSD" />
            </el-table-column>

            <el-table-column :label="$t('okex')">
              <el-table-column prop="okexActive" min-width="160" :label="$t('activeSuspended')">
                <template slot-scope="scope">
                  <span :class="{ 'text-success': scope.row.okexActive, 'text-danger': !scope.row.okexActive }"> {{ scope.row.okexActive ? $t('yes') : $t('no') }} </span>
                  /
                  <span :class="{ 'text-success': !scope.row.suspendedOkex, 'text-danger': scope.row.suspendedOkex }"> {{ scope.row.suspendedOkex ? $t('yes') : $t('no') }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="usdtOkex"  label="USDT" />
            </el-table-column>
          </el-table>
        </div>
        <div class="text-center mb-2 spinner-grow spinner-grow-xl" v-if="requestOn" v-loading="true"> </div>
        <div class="text-center" v-if="users.length === 0 && requestOn === false"> {{ $t('none') }} </div>
      </card>
    </div>
  </div>
</template>
<script>

import { Table, TableColumn } from 'element-ui'
import { BaseAlert, BaseCheckbox, Badge, Modal, Collapse, CollapseItem } from '@/components'
import { companyUsers, companyUpdateUser, listOrderCompany, accountStatusCompany } from '@/services/index'
import { mapGetters, mapActions } from 'vuex'
import { setUsersCompany } from '../../../../adapters/index'

function sleep(s) {
  return new Promise(resolve => setTimeout(resolve, s));
}

export default {
  props: ['ws'],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseAlert,
    BaseCheckbox,
    Badge,
    Modal,
    Collapse,
    CollapseItem
  },
  data() {
    return {
      requestOn: false,
      requestOnUp: false,
      switchOn: false,
      loadingTime: false,
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      users: 'company/users',
      user: 'company/user',
      valueInPurchases: "company/valueInPurchases",
      deficit: "company/deficit",
      sumProfit: "company/sumProfit",
      sumSymbolsOnHold: "company/sumSymbolsOnHold",
      sumOfConfirmedAmountOnHold: "company/sumOfConfirmedAmountOnHold",
      profit: "company/profit",
      riskRate: "company/riskRate",
      capitalAtRisk: "company/capitalAtRisk",
      onlyView: "user/onlyView",
    }),

    massEditing: function() {
      return this.users.filter(o => o.selected === true)
    },

    profit: function() {
      return parseFloat(((this.sumProfit + this.sumOfConfirmedAmountOnHold + this.sumSymbolsOnHold) - this.deficit).toFixed(2))
    },

  },
  methods: {
    ...mapActions({
      setUsers: "company/setUsers",
      setOrders: "company/setOrders",
      setUser: "company/setUser",
    }),

    copyText(value) {
      const text = value.replace(/\s/g, '')
      var textArea = document.createElement("textarea");
      textArea.value = `https://business-fe6f5.web.app/compnay/view?auth=${text}`;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.$notify({ type: 'success', message: this.$t("copiedText") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 5000 });
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    },

    verifySelected(value) {
      this.users.forEach(o => o.selected = value)
    },

    async listUsers() {
      this.requestOn = true
      try {
        const { data } = await companyUsers()
        const mapData = data.map(d => {
          d.orders = []
          d.selected = false
          d.operations = []
          d.synchronize = false
          d.wallets = { binance: [], kucoin: [], huobi: [], okex: [] }
          return d
        })
        this.setUsers(setUsersCompany(this.users, mapData, false))

        const response = await listOrderCompany()
        this.setOrders(response.data)
      } catch (e) {
        console.info(e.message)
      }
      this.requestOn = false
    },

    async actionUser(userId, field, value) {
      if (!confirm(this.$t("doYouReally"))) return

      this.requestOnUp = true

      try {
        let body = {}
        body[field] = value
        await companyUpdateUser(userId, body)
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
      } catch (e) {
       const { status, data = [] } = e.response || {}
       let message = e.message
       if (status === 422) {
         let erros = []
         for (const item in data) {
           erros.push(data[item].join("\n"))
         }
         message = erros.join("\n")
       }
       this.$notify({ type: 'warning', message, verticalAlign: 'bottom', horizontalAlign: 'left', timeout: 10000 });
      }

      setTimeout(() => this.requestOnUp = false, 10000);
    },

    action(user, name) {
      this.setUser(user)
      this.$router.push({ name })
    },

    async runGenerateReports() {
      if (confirm(this.$t("doYouReally"))) {
        this.requestOnUp = true

        for (const raw of this.massEditing) {
          try {
            this.setUser(raw)
            await sleep(300)
            const body = {
              'company_user_id': raw.id,
              'valueInPurchases': this.valueInPurchases,
              'deficit': this.deficit,
              'profit': this.sumProfit,
              'altcoinSaved': this.sumSymbolsOnHold,
              'altcoinSold': this.sumOfConfirmedAmountOnHold,
              'netIncome': this.profit,
              'riskRate': this.riskRate(),
              'capitalAtRisk': this.capitalAtRisk
            }

            await accountStatusCompany(body)

            await sleep(700)
          } catch (e) {
            console.error(e)
          }
          raw.selected = false
        }

        this.requestOnUp = false
        this.switchOn = false
        this.loadingTime = false
        setTimeout(() => this.loadingTime = true, 0)
        this.$notify({ type: 'success', message: this.$t("requestSuccessful") , verticalAlign: 'top', horizontalAlign: 'center', timeout: 15000 });
      }
    }
  },
  async created() {
    await this.listUsers()
    setTimeout(() => this.loadingTime = true, 10000)
  },
  mounted() {

  }
};
</script>

<style scoped>
.copyOne {
  cursor: pointer;
  font-weight: 900;
}
</style>
